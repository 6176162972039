import React, { Component } from "react";
import errorcodes from "../errorcodes";
import messages from "../../Messages/Messages";
import componentsStrings from "../../Messages/Components";
import Col from "react-bootstrap/Col";
import connections from "../../connections"
import Modal from 'react-bootstrap/Modal'
import { Row } from "react-bootstrap";
import "../../css/general.css"
import "../../css/colors.css"
import "../../css/texts.css"
import EyeInput from "./eyeInput";
import DiseasesList from "./diseasesList";
import ExamButtons from "./examButtons";
import Moment from 'moment/moment'
import texts from "./Texts";
import { Redirect } from "react-router";


class AEYEDisease {
  constructor() {
    this.diagnose = 0;
    this.diagnosis = 0;
    this.disease = 0;
    this.diseasedescription = "";
    this.diseasename = "";
    this.id = 0;
    this.side = "";
    this.chresults = "";
  }
}

class AEYEDiagnosticDetails {
  constructor() {
    this.id = 0;
    this.patientid = "";
    this.patientname = "";
    this.diagnosedon = 0;
    this.uploadedon = 0;
    this.diagnoseby = "";
    this.fullname = "";
    this.clinicfullname = "";
    this.comments = "";
    this.doctorid = 0;
    this.automatic = 0;
    this.image_id = 0;
    this.image_file_l = 0;
    this.image_file_r = 0;
    this.image_file_l_name = "";
    this.image_file_r_name = "";
    this.image_content_file_l = 0;
    this.image_content_file_r = 0;
    this.image_file_l_d = 0;
    this.image_file_r_d = 0;
    this.image_file_l_d_name = "";
    this.image_file_r_d_name = "";
    this.image_content_file_l_d = 0;
    this.image_content_file_r_d = 0;
    this.bad_l = 0;
    this.bad_r = 0;
    this.bad_l_d = 0;
    this.bad_r_d = 0;
    this.bad_p = 0;
    this.results = "";
    this.discexist = false;
    this.diseasesL = [];
    this.diseasesR = [];
    this.diseasesP = [];
    this.diseasesL_d = [];
    this.diseasesR_d = [];
    this.diseasesP_two = [];
    this.image_type_r = '';
    this.image_type_l = '';    
  }
}

class Image {
  name;
  type;
  content;
  src;
  iiq;
  loaded;
}


class ExamDisplayForm extends Component {
  
  state = {
    fatchData: false,
    timer: null,
    imageRightWasLoaded: false,
    imageLeftWasLoaded: false,
    localDate: '',
    diagnosticdetails: null,
    archived: false,
    displayDownloadPDFErrorMessage: false,
    displayGetDetailsErrorMessage: false,
    redirectToLogin: false,
    toastMessageText: "",
    content_r: "",
    content_l: ""
  };

  componentDidMount() {

    var date = new Date();
    var options = { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", hour12: false };
    var localDate = date.toLocaleDateString("en", options)

    this.setState({localDate: localDate})
  }

  componentDidUpdate() {

    if(!this.state.diagnosticdetails && !this.props.testreport) {
      this.fetchDetails();
    }
    else if(this.props.testreport && !this.state.diagnosticdetails) {
      this.fetchDetails();
    }
    else {

      if(this.props.exam)  {

        if(this.props.exam.imagedbid != this.state.diagnosticdetails.id) {
          this.setState({ diagnosticdetails: null });
          this.fetchDetails();
        }
        else if(this.props.exam.diagnosed === 1 && this.props.exam.diagnosesid !== 0 && this.state.diagnosticdetails.diagnosedon === '') {
          this.fetchDetails();
        }
      }
      else if(!this.props.testreport) {
        this.setState({ diagnosticdetails: null });
      }
    }

  }

  onLoadRight = () => {

    this.setState({ imageRightWasLoaded: true });
  }

  onLoadLeft = () => {

    this.setState({ imageLeftWasLoaded: true });
  }

  handleDisplayDownloadPDFErrorMessage = event => {
      this.setState({displayDownloadPDFErrorMessage: false})
  }

  handleDownloadDiagnosisReport = () => {

    if((this.props.exam && this.props.exam.imagedbid > 0 && this.props.exam.diagnosed > 0) || this.props.testreport) {
      this.downloadDocument()
    }    
  }
  handleDownloadDiagnosisReportNew = () => {

    if((this.props.exam && this.props.exam.imagedbid > 0 && this.props.exam.diagnosed > 0) || this.props.testreport) {
      this.downloadDocumentNew()
    }    
  }

  downloadDocument = () => {

      this.setState({toastMessageText: componentsStrings.patientview_downloading_body });
      let imagedbid = this.state.diagnosticdetails.id;
      let datediagnosed = Moment(this.state.diagnosticdetails.diagnosedon).format("MMM DD YYYY");
      let url = connections.sdkurl;
      let theurl = url + "/AEYEAppServer/v3/Images/downloadpdf";
      fetch(theurl,  {
          method: "POST",
          body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          imagedbid: imagedbid
          })
        }).then(response => {
          if(response.ok == true && response.status == 200) {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              let examid = this.props.exam ? this.props.exam.examid : '';
              a.download = examid + '_' + datediagnosed + '.pdf';
              a.click();
              this.setState({toastMessageText: ""});
            }).catch(e => {
              this.setState({ displayDownloadPDFErrorMessage: true, toastMessageText: "" });
            });
          } else if(response.ok == false) {
            if((response.status + errorcodes.errorOK) == errorcodes.AEYEHealthSDKSessionTimeout) {
              this.props.sessionData.showsessiontimeout();
              return;
            } else {
              this.setState({displayDownloadPDFErrorMessage: true, toastMessageText: ""});
            }
            }
      }).catch(e => {
        this.setState({ displayDownloadPDFErrorMessage: true, toastMessageText: "" });
      });
  

  }
  downloadDocumentNew = () => {

    this.setState({toastMessageText: componentsStrings.patientview_downloading_body });
    let imagedbid = this.state.diagnosticdetails.id;
    let datediagnosed = Moment(this.state.diagnosticdetails.diagnosedon).format("MMM DD YYYY");
    let url = connections.sdkurl;
    let theurl = url + "/AEYEAppServer/v3/Images/downloadpdfnew";
    fetch(theurl,  {
        method: "POST",
        body: JSON.stringify({
        logonid: this.props.username,
        session: this.props.session,
        imagedbid: imagedbid
        })
      }).then(response => {
        if(response.ok == true && response.status == 200) {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            let examid = this.props.exam ? this.props.exam.examid : '';
            a.download = examid + '_' + datediagnosed + '.pdf';
            a.click();
            this.setState({toastMessageText: ""});
          }).catch(e => {
            this.setState({ displayDownloadPDFErrorMessage: true, toastMessageText: "" });
          });
        } else if(response.ok == false) {
          if((response.status + errorcodes.errorOK) == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          } else {
            this.setState({displayDownloadPDFErrorMessage: true, toastMessageText: ""});
          }
          }
    }).catch(e => {
      this.setState({ displayDownloadPDFErrorMessage: true, toastMessageText: "" });
    });


  }

  handleSendDiagnosisReport = () => {

    if(this.props.exam && this.props.exam.imagedbid > 0 && this.props.exam.diagnosed > 0) {
      let imagedbid = this.props.exam.imagedbid;
      let patientdbid = this.props.exam.patientdbid;
  
      this.sendDocument(imagedbid, patientdbid)
    }
  }

  sendDocument = (imagedbid, patientdbid) => {
    let url = connections.sdkurl;
    this.setState({toastMessageText: componentsStrings.patientview_sendemail_body})

    fetch(url + "/AEYEAppServer/v4/Patients/sendDiagnosis", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          imagedbid: imagedbid,
          patientdbid: patientdbid
        }),
      })
        .then(response => response.json())
        .then(data => {
          this.setState({toastMessageText: ""})
         
          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }
    
          if (data.errorcode == errorcodes.errorOK) {
            var sendmsg = componentsStrings.patientview_successsendemail_body;
            sendmsg = sendmsg.replace("$$mailto$$", data.emailto);
            this.setState({sendDiagnosisMessage: sendmsg});
          } else {
            if(data.emailto == "") {
              this.setState({sendDiagnosisMessage: componentsStrings.patientview_failedsendemail_body});
            } else {
              let sendmsg = componentsStrings.patientview_failedtosendemail_body;
              sendmsg.replace("$$mailto$$", data.emailto);
              this.setState({sendDiagnosisMessage: sendmsg});
            }
          }
        });
  }

  handleArchiveUnarchiveDiagnosis = () => {
    
    if(this.props.exam && this.props.exam.imagedbid > 0 && this.props.exam.diagnosed > 0) {

      let dataToArchive = [];
      class OneDiag {
        patientdbid = 0;
        imagedbid = 0;
      }
  
      let onediag = new OneDiag();
      onediag.patientdbid = this.props.exam.patientdbid;
      onediag.imagedbid = this.props.exam.imagedbid;
      dataToArchive.push(onediag);
  
      this.archiveDiagnosis(dataToArchive)
    }
  }

  archiveDiagnosis = (dataToArchive) => {

    this.setState({toastMessageText: this.state.archived === true ? "Restoring from archive" : 'Archiving' })
    
    let url = connections.sdkurl;
    fetch(url + "/AEYEAppServer/v4/Patients/archiveDiagnosis", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          diagnosistoarchive: dataToArchive,
          toarchive: !this.state.archived
        }),
      })
        .then(response => response.json())
        .then(data => {

          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }

          if (data.errorcode == errorcodes.errorOK) {
            let timer = setInterval(() => { this.clearToastMessageText() }, 1500);
            this.setState({ archived: !this.state.archived, timer: timer });
            this.props.onDoneCancelingExam();
          }
          else {
            this.setState({ toastMessageText: ("errorcode: " + data.errorcode) });
            let timer = setInterval(() => { this.clearToastMessageText() }, 2500);
            this.setState({ timer: timer });            
          }

        });
  }

  clearToastMessageText() {

    this.setState({ toastMessageText: ""});

    if(this.state.timer != null) {
      clearTimeout(this.state.timer);
      this.setState({ timer: null });
    }
  }

  displayToastMessageBox() {
    return(
    <Modal show={this.state.toastMessageText !== ''} centered dialogClassName={"toast-popup"}>

        <Modal.Body>{this.state.toastMessageText}</Modal.Body>

    </Modal>        
    );
  }

  downloadPDFErrorMessageBox() {
    return(
    <Modal show={this.state.displayDownloadPDFErrorMessage} centered dialogClassName={"iiq-popup"}>
        
        <Modal.Header>
          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {messages.table_errordownloadpdf_title} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>{messages.table_errordownloadpdf_body}</Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleDisplayDownloadPDFErrorMessage}>
            {componentsStrings.button_close}
          </Col>

        </Row>

    </Modal>        
    );
  }

  displayGetDetailsErrorMessageBox() {

    return(
      <Modal show={this.state.displayGetDetailsErrorMessage} centered dialogClassName={"iiq-popup"}>

        <Modal.Header>
          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Header>

          <Modal.Title> 
            {messages.report_get_details_error_title} 
          </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>

          {<div>                
            {messages.report_get_details_error_body} 
          </div>}

        </Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>
 
          <Col style={{ padding: '2%', cursor: 'default' }} onClick={this.handleCloseGetDetailsErrorMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>        
    );
    
  }

  handleCloseGetDetailsErrorMessageBox = event => {
    this.setState({ redirectToLogin: true, displayGetDetailsErrorMessage: false });
  }


  fetchDetails = () => {

    if(this.props.session && (this.props.exam || this.props.testreport) && !this.state.fatchData) {

      this.setState({ fatchData: true, content_r: '', content_l: '' });

      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v4/Patients/getDiagnosticDetailsNew", {
            method: "POST",
            body: JSON.stringify({
              logonid: this.props.username,
              session: this.props.session,
              patientdbid: this.props.exam ? this.props.exam.patientdbid : 0,
              imagedbid: this.props.exam ? this.props.exam.imagedbid : 0,
              diagnosesid:this.props.exam ? this.props.exam.diagnosesid : 0,
              diagstate: this.props.exam ? this.props.exam.diagstate : 0,
              testreport: this.props.testreport ? this.props.testreport : ''
            })
      })
      .then(response => response.json())
      .then(data => {

        if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
          this.props.sessionData.showsessiontimeout();
          return;
        }

        if(data.errorcode == errorcodes.AEYEHealthSDKFailedToLogin) {
          this.props.displayFailedToLoginMessageBox();
          return;
        }        

        if (data.errorcode == errorcodes.errorOK) {
          let aeyediagnosticdetails = new AEYEDiagnosticDetails();

          aeyediagnosticdetails.id = data.id;
          aeyediagnosticdetails.patientid = data.patientid;
          aeyediagnosticdetails.patientname = data.patientname;
          aeyediagnosticdetails.diagnosedon = data.diagnosedon;
          aeyediagnosticdetails.uploadedon = data.uploadedon;
          aeyediagnosticdetails.comments = data.comments;
          aeyediagnosticdetails.doctorid = data.doctorid;
          aeyediagnosticdetails.automatic = data.automatic;

          let diagnosedBy = messages.table_diagby_algo;
          if (!Boolean(Number(data.automatic))) {
            diagnosedBy = data.doctorname;
          }

          aeyediagnosticdetails.diagnoseby = diagnosedBy;
          aeyediagnosticdetails.fullname = data.clinicfullname;
          aeyediagnosticdetails.clinicfullname = data.clinicfullname;

          aeyediagnosticdetails.image_id = data.image_id;
          aeyediagnosticdetails.image_file_l = data.image_file_l;
          aeyediagnosticdetails.image_file_r = data.image_file_r;
          aeyediagnosticdetails.image_file_l_name = data.image_file_l_name;
          aeyediagnosticdetails.image_file_r_name = data.image_file_r_name;
          aeyediagnosticdetails.image_content_file_l = data.image_content_file_l;
          aeyediagnosticdetails.image_content_file_r = data.image_content_file_r;
          aeyediagnosticdetails.bad_l = data.bad_l;
          aeyediagnosticdetails.bad_r = data.bad_r;
          aeyediagnosticdetails.bad_p = data.bad_p;
          
          aeyediagnosticdetails.image_type_r = data.image_type_r;
          aeyediagnosticdetails.image_type_l = data.image_type_l;

          aeyediagnosticdetails.discexist = data.discexist;
          if(data.discexist == true) {
            aeyediagnosticdetails.image_file_l_d = data.image_file_l_d;
            aeyediagnosticdetails.image_file_r_d = data.image_file_r_d;
            aeyediagnosticdetails.image_file_l_d_name = data.image_file_l_d_name;
            aeyediagnosticdetails.image_file_r_d_name = data.image_file_r_d_name;
            aeyediagnosticdetails.image_content_file_l_d = data.image_content_file_l_d;
            aeyediagnosticdetails.image_content_file_r_d = data.image_content_file_r_d;
            aeyediagnosticdetails.bad_l_d = data.bad_l_d;
            aeyediagnosticdetails.bad_r_d = data.bad_r_d;
          }

          aeyediagnosticdetails.diseasesL = [];
          aeyediagnosticdetails.diseasesR = [];
          aeyediagnosticdetails.diseasesP = [];
          if(data.discexist == true) {
            aeyediagnosticdetails.diseasesL_d = [];
            aeyediagnosticdetails.diseasesR_d = [];
            aeyediagnosticdetails.diseasesP_two = [];
          }
          if(data.diseases) {
              for (const [index, disease] of data.diseases.entries()) {
                  let aeyedisease = new AEYEDisease();
                  aeyedisease.diagnose = disease.diagnose;
                  aeyedisease.diagnosis = disease.diagnosis;
                  aeyedisease.disease = disease.disease;
                  aeyedisease.diseasedescription = disease.diseasedescription;
                  aeyedisease.diseasename = disease.diseasename;
                  aeyedisease.id = disease.id;
                  aeyedisease.side = disease.side;
                  aeyedisease.chresults = disease.chresults;

                    if(disease.side == "L") {
                        aeyediagnosticdetails.diseasesL.push(aeyedisease)
                    } else if(disease.side == "R") {
                        aeyediagnosticdetails.diseasesR.push(aeyedisease)
                    } else if(disease.side == "P") {
                        aeyediagnosticdetails.diseasesP.push(aeyedisease);
                        aeyediagnosticdetails.comments = disease.comments;
                    } else if(disease.side == "L_d") {
                      aeyediagnosticdetails.diseasesL_d.push(aeyedisease)
                    } else if(disease.side == "R_d") {
                      aeyediagnosticdetails.diseasesR_d.push(aeyedisease)
                    } else {
                      aeyediagnosticdetails.diseasesP_two.push(aeyedisease)
                    }

              }
          }
          
          this.setState({diagnosticdetails: aeyediagnosticdetails, fatchData: false, archived: data.archived, imageRightWasLoaded: false, imageLeftWasLoaded: false},
              this.fetchImage(data.image_content_file_r, true),
              this.fetchImage(data.image_content_file_l, false)
          );
          
        }
        else {
          if(this.props.testreport) {
            this.setState({ displayGetDetailsErrorMessage: true });
          }
        }
      });

    }

  }


  fetchImage = (content_id, isRightEye) => {

    if(this.props.session && (this.props.exam || this.props.testreport) && content_id > 0) {

      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v3/Images/getimageNew", {
            method: "POST",
            body: JSON.stringify({
              logonid: this.props.username,
              session: this.props.session,
              image_content_file: content_id 
            })
      })
      .then(response => response.json())
      .then(data => {

        if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
          this.props.sessionData.showsessiontimeout();
          return;
        }

        if(data.errorcode == errorcodes.AEYEHealthSDKFailedToLogin) {
          this.props.displayFailedToLoginMessageBox();
          return;
        }        

        if (data.errorcode == errorcodes.errorOK) {

            if(isRightEye === true) {
              this.setState({ content_r: data.content });
            }
            else {
              this.setState({ content_l: data.content });
            }

        }

      })
      .catch(e => {
        // console.log(e);
      });

    }

  }

  render() {

    if(this.state.redirectToLogin) {
      return <Redirect to={"/"} />
    }

    let srcR = "";
    let srcL = "";
    let bad_r = 0;
    let bad_l = 0;
    let bad_p = 0;
    let image_l_name = "";
    let image_r_name = "";

    let patientname = "";
    let patientexamid = '';
    let marginTop = " mt-4 pt-5";
    let diagnosed = false;
    let examdate = '';

    if(this.state.diagnosticdetails) {

        var image_r = new Image();
        var image_l = new Image();

        image_r_name = this.state.diagnosticdetails.image_file_r_name;
        image_l_name = this.state.diagnosticdetails.image_file_l_name;
        bad_r = this.state.diagnosticdetails.bad_r;
        bad_l = this.state.diagnosticdetails.bad_l;
        bad_p = this.state.diagnosticdetails.bad_p;
        
        srcR = 'data:image/' + this.state.diagnosticdetails.image_type_r + ';base64, ' + this.state.content_r;
        srcL = 'data:image/' + this.state.diagnosticdetails.image_type_l + ';base64, ' + this.state.content_l;

        // srcR = 'data:image/' + this.state.diagnosticdetails.image_type_r + ';base64, ' + this.state.content_r;


        patientname = this.state.diagnosticdetails.patientname;
        if(patientname.length > 50) {
          patientname = messages.table_desisdetails_dataencrypted;
        }

        patientexamid = this.state.diagnosticdetails ? this.state.diagnosticdetails.patientname: '';
        diagnosed = this.props.exam ? this.props.exam.diagnosed: false;

        examdate = this.props.exam ? (this.props.exam.date + " " + this.props.exam.time) : '';

        if(this.props.testreport) {
          diagnosed = true;
          examdate = this.state.diagnosticdetails ? Moment(this.state.diagnosticdetails.diagnosedon).format("MMM DD YYYY") + Moment(this.state.diagnosticdetails.diagnosedon).format("HH:mm") : '';
        }  

        image_r.src = srcR;
        image_r.content = srcR;
        image_r.name = image_r_name;
        image_r.iiq = bad_r;
        image_r.loaded = false;

        image_l.src = srcL;
        image_l.content = srcL;
        image_l.name = image_l_name;
        image_l.iiq = bad_l;
        image_l.loaded = false;

        var imagesWasLoaded = true;
        if(this.state.imageRightWasLoaded === false || this.state.imageLeftWasLoaded === false) {
          image_r.loaded = true;
          image_l.loaded = true;
          imagesWasLoaded = false;
        }

        if(this.state.fatchData === true) {
          image_r.src = '';         
          image_l.src = '';
        }
        

        if(bad_r === 1 || bad_l === 1) {
          marginTop = "";
        }
    }

    return (
        <React.Fragment>

          {this.displayToastMessageBox()}
          {this.downloadPDFErrorMessageBox()}
          {this.displayGetDetailsErrorMessageBox()}

          <Col className="bg-black scroll exam-scroll">
            
            <div className="exam-container">

              {/* date */}
              <Row>
                
                <Col>
                  <div className="fs16 text gray_3 mt-4 mb-3">
                    {examdate}
                  </div>
                </Col>

                <Col align="right">
                  <div className="fs16 text gray_3 mt-4 mb-3">
                    {'Status: '} {this.state.archived ? 'Archived' : 'Active'}
                  </div>
                </Col>

              </Row>

              {/* buttons */}
              <Row>

                {1==0 && <Col>
                  <ExamButtons handleDownloadDiagnosisReport={diagnosed && imagesWasLoaded === true && this.handleDownloadDiagnosisReport} 
                                handleSendDiagnosisReport={diagnosed && imagesWasLoaded === true && this.handleSendDiagnosisReport} 
                                handleArchiveUnarchiveDiagnosis={diagnosed && imagesWasLoaded === true && this.handleArchiveUnarchiveDiagnosis} 
                                isActive={!this.state.archived}
                                testreport={this.props.testreport}>                        
                  </ExamButtons>
                </Col>}
                <Col>
                  <ExamButtons handleDownloadDiagnosisReport={diagnosed && imagesWasLoaded === true && this.handleDownloadDiagnosisReport} 
                                handleSendDiagnosisReport={diagnosed && imagesWasLoaded === true && this.handleSendDiagnosisReport} 
                                handleArchiveUnarchiveDiagnosis={diagnosed && imagesWasLoaded === true && this.handleArchiveUnarchiveDiagnosis} 
                                handleDownloadDiagnosisReportNew={diagnosed && imagesWasLoaded === true && this.handleDownloadDiagnosisReportNew} 
                                isActive={!this.state.archived}
                                testreport={this.props.testreport}>                        
                  </ExamButtons>
                </Col>
                
              </Row>

              {/* exam id */}
              <Row>

                <Col>
                  <div>

                      <div id="uploadexam_id" className="fs30 fw400 text white">
                        {patientexamid}
                      </div>

                  </div>
                </Col>

              </Row>

              {/* eyes */}
              <Row>

                <Col>

                  {(bad_r === 1 || bad_l === 1) && <div className="text-center lh28 fs18 text red mt-4 pt-1 mb-3">
                      {texts.display_exam__iiq}
                  </div>}
                
                  <div className={"d-flex justify-content-between align-items-start mb-3" + marginTop}>

                    <EyeInput id="uploadpatient_righteye" inputid={"fileinputright"} image = {image_r} label={componentsStrings.uploadpatient_righteye} 
                              isDisplayMode={true} colStyle={"mt-3 pt-1 pl-0 pr-0 mb-5"} diagnosed={diagnosed} exam={this.props.exam} onLoad={this.onLoadRight}>
                    </EyeInput>
                    
                    <EyeInput id="uploadpatient_lefteye" inputid={"fileinputleft"} image = {image_l} label={componentsStrings.uploadpatient_lefteye} 
                              isDisplayMode={true} colStyle={"mt-3 pt-1 pr-0 pl-0 mb-5 text-align-webkit-right"} diagnosed={diagnosed} exam={this.props.exam} onLoad={this.onLoadLeft}>
                    </EyeInput>

                  </div>
                </Col>

              </Row>

            </div>

          </Col>

          <Col className="bg-black p-0 scroll diseases-scroll left-border" style={{maxWidth: "22.5%", height: '-webkit-fill-available'}}>

            <DiseasesList session={this.props.session} sessionData={this.props.sessionData}
                        username={this.props.username} clinicid={this.props.clinicid} imagedbid={this.state.imagedbid} 
                        diagstate={this.state.diagstate} diagnosticdetails={this.state.diagnosticdetails} 
                        discexist={this.props.discexist} bad_r={bad_r} bad_l={bad_l} bad_p={bad_p}
                        >

            </DiseasesList>

          </Col>

        </React.Fragment>
    );

  }
  
}

export default ExamDisplayForm;